import 'owl.carousel'

jQuery(document).ready(($) => {
	$('.owl-carousel--js').owlCarousel({
    items: 1,
		autoplay: true,
		loop: true,
		margin: 10,
		nav: true,
		dots: false,
		autoHeight: false,
		autoHeightClass: 'owl-height',
		navText: ['<svg class="icon icon-chevron-left"><use xlink:href="./images/sprite.svg#chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="./images/sprite.svg#chevron-right"></use></svg>']	
	})
})
